import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "author": "nateVick",
  "categories": ["rails"],
  "date": "2020-03-26",
  "path": "/blog/activestorage-s3-sse",
  "summary": "Use S3 server-side encryption with ActiveStorage.",
  "title": "ActiveStorage & S3 Server-side Encryption",
  "image": "./ogp.jpg",
  "seo": {
    "og": {
      "type": "article"
    },
    "twitter": {
      "card": "summary_large_image",
      "creator": "@natron99"
    }
  }
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`TIL, it is possible to use S3 server-side encryption and ActiveStorage.`}</p>
    <p>{`This `}<a parentName="p" {...{
        "href": "https://github.com/rails/rails/commit/32331b19e1da8bdab3c9f6d1666ac2d3108e5042"
      }}>{`commit to Rails`}</a>{` in 2017 adds the ability but did not add documentation or an example of how to use the `}<inlineCode parentName="p">{`upload_options`}</inlineCode>{` feature. Below is a vanilla S3 service config for ActiveStorage.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yaml"
    }}><pre parentName="div" {...{
        "className": "language-yaml"
      }}><code parentName="pre" {...{
          "className": "language-yaml"
        }}><span parentName="code" {...{
            "className": "token key atrule"
          }}>{`amazon`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`service`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` S3
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`access_key_id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` ACCESS_KEY_ID
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`secret_access_key`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` SECRET_ACCESS_KEY
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`region`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` us`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`east`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`bucket`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` BUCKET`}</code></pre></div>
    <p>{`Here is a S3 service config using `}<inlineCode parentName="p">{`upload`}</inlineCode>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yaml"
    }}><pre parentName="div" {...{
        "className": "language-yaml"
      }}><code parentName="pre" {...{
          "className": "language-yaml"
        }}><span parentName="code" {...{
            "className": "token key atrule"
          }}>{`amazon`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`service`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` S3
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`access_key_id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` ACCESS_KEY_ID
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`secret_access_key`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` SECRET_ACCESS_KEY
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`region`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` us`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`east`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`bucket`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` BUCKET
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`upload`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`server_side_encryption`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'aws:kms'`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# 'AES256'`}</span></code></pre></div>
    <p>{`The `}<inlineCode parentName="p">{`upload`}</inlineCode>{` hash is passed to `}<inlineCode parentName="p">{`Aws::S3::Client#put_object(params = {})`}</inlineCode>{`. One of the configuration options for `}<inlineCode parentName="p">{`put_object`}</inlineCode>{` is `}<inlineCode parentName="p">{`:server_side_encryption (String)`}</inlineCode>{`. For more options checkout the `}<a parentName="p" {...{
        "href": "https://docs.aws.amazon.com/sdk-for-ruby/v3/api/Aws/S3/Client.html#put_object-instance_method"
      }}>{`Ruby SDK docs`}</a>{`.`}</p>
    <p>{`💡If you are using KMS keys, the bucket user will need the following policies:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}>{`(`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"kms:Decrypt"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"kms:Encrypt"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"kms:GenerateDataKey"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"kms:ReEncryptTo"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"kms:DescribeKey"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"kms:ReEncryptFrom"`}</span>{`)`}</code></pre></div>
    <p>{`To help other Rails devs, here is a `}<a parentName="p" {...{
        "href": "https://github.com/rails/rails/pull/38801"
      }}>{`PR to Rails`}</a>{` to add the above example to the official guides.`}</p>
    <p>{`Have a great day!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      